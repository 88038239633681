<template>
    <custom-vue-table  class="pl-3 pr-3" :showSearchBox="false" title="Notifications" :url="listUrl" :fields="fields"
                    :per-page="10" ref="table"/>
</template>

<script>
import urls from '../../data/urls';

export default {
    name : 'Notifications',
    data () {
        return {
            listUrl : urls.notifications,
            fields  : [
                {
                    name      : 'message',
                    sortField : 'message',
                    title     : 'Message'
                },
                {
                    name      : 'created_date',
                    sortField : 'created_date',
                    title     : 'Date'
                },
                {
                    name      : 'created_time',
                    sortField : 'created_time',
                    title     : 'Time'
                }
            ]
        };
    }
};

</script>
